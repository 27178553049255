import React from 'react'
import { PropTypes } from 'prop-types'
import { Redirect, Route, Switch } from 'react-router-dom'
import routesAuthorized from 'routesAuthorized'
import Sidebar from 'components/Sidebar/Sidebar'
import AuthorizedNavbar from 'components/Navbars/AuthorizedNavbar'
import AuthFooter from 'components/Footers/AuthFooter'

class Authorized extends React.Component {
  state = {
    sidenavOpen: true
  };

  componentDidMount () {
    document.body.classList.remove('bg-gradient-success')
  }

  componentDidUpdate (e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0
      document.scrollingElement.scrollTop = 0
      // this.refs.mainContent.scrollTop = 0
    }
  }

  getRoutes = routesAuthorized => {
    return routesAuthorized.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views)
      }
      if (prop.layout === '/authorized') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
      } else {
        return null
      }
    })
  };

  getBrandText = path => {
    for (let i = 0; i < routesAuthorized.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routesAuthorized[i].layout + routesAuthorized[i].path
        ) !== -1
      ) {
        return routesAuthorized[i].name
      }
    }
    return 'Brand'
  };

  toggleSidenav = e => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned')
      document.body.classList.add('g-sidenav-hidden')
    } else {
      document.body.classList.add('g-sidenav-pinned')
      document.body.classList.remove('g-sidenav-hidden')
    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen
    })
  };

  getNavbarTheme = () => {
    return this.props.location.pathname.indexOf(
      'admin/alternative-dashboard'
    ) === -1
      ? 'dark'
      : 'light'
  };

  render () {
    return (
      <>
        <Sidebar
          {...this.props}
          routes={routesAuthorized}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={this.state.sidenavOpen}
          logo={{
            innerLink: '#',
            imgSrc: require('assets/img/brand/app-logo.png').default,
            imgAlt: '...'
          }}
        />
        <div
          className="main-content"
          // ref="mainContent"
          onClick={this.closeSidenav}
        >
          <AuthorizedNavbar
            {...this.props}
            theme={this.getNavbarTheme()}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sidenavOpen}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <Switch>
            {this.getRoutes(routesAuthorized)}
            <Redirect from="*" to="/authorized/beranda/rekapitulasi" />
          </Switch>
          <AuthFooter />
        </div>
        {this.state.sidenavOpen
          ? (
          <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
            )
          : null}
      </>
    )
  }
}

Authorized.propTypes = {
  location: PropTypes.object.isRequired
}

export default Authorized
