// /* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardTitle, Container, Row, Col } from 'reactstrap'
import { PropTypes } from 'prop-types'
import _ from 'lodash'
import ValidationAuthorized from 'utils/ValidationAuthorized'
import Axios from 'helpers/Axios'

const Rekapitulasi = (props) => {
  Rekapitulasi.propTypes = {
    token: PropTypes.string.isRequired
  }

  const [state, setState] = useState({
    data: null
  })

  useEffect(() => {
    getData()
  }, [props])

  const getData = () => {
    Axios({
      method: 'GET',
      url: '/unit-pengolah/rekapitulasi',
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          data: res.data
        }))
      })
      .catch(err => {
        console.error('ERROR!', err)
      })
  }

  return (
    <>
      {/* {console.log(state)} */}
      <div className="header bg-success pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              {_.isNull(state.data)
                ? null
                : <>
                <TemplateCard
                    labelName={'Daftar Berkas (Aktif)'}
                    count={state.data.daftarBerkasAktif}
                    icon={'fa fa-folder-open'}
                    iconFrame={'icon icon-shape bg-gradient-red text-white rounded-circle shadow'}
                />
                <TemplateCard
                    labelName={'Daftar Berkas (Sudah Pindah)'}
                    count={state.data.daftarBerkasSudahPindah}
                    icon={'fa fa-folder-open'}
                    iconFrame={'icon icon-shape bg-gradient-default text-white rounded-circle shadow'}
                />
                <TemplateCard
                    labelName={'Transaksi Peminjaman (Belum Kembali)'}
                    count={state.data.sirkulasiTransaksiPeminjamanBelumKembali}
                    icon={'fa fa-shopping-bag'}
                    iconFrame={'icon icon-shape bg-gradient-blue text-white rounded-circle shadow'}
                />
                <TemplateCard
                    labelName={'Penyusutan (Sudah Pindah)'}
                    count={state.data.penyusutanSudahPindah}
                    icon={'fa fa-truck'}
                    iconFrame={'icon icon-shape bg-gradient-green text-white rounded-circle shadow'}
                />
              </>}

            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default ValidationAuthorized(Rekapitulasi)

const TemplateCard = (props) => {
  TemplateCard.propTypes = {
    labelName: PropTypes.string.isRequired,
    iconFrame: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    count: PropTypes.number.isRequired
  }

  return (
    <>
      <Col lg="6" xl="3">
        <Card className="card-stats mb-4 mb-xl-0">
          <CardBody>
            <Row>
              <div className="col">
                <CardTitle
                  tag="h5"
                  className="text-uppercase text-muted mb-0"
                >
                  {props.labelName}
                </CardTitle>
                <span className="h2 font-weight-bold mb-0">{props.count}</span>
              </div>
              <Col className="col-auto">
                <div className={props.iconFrame}>
                  <i className={props.icon} />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </>
  )
}
