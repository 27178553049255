import Toastr from 'toastr'

export default class HttpResponseNotification {
  constructor () {
    this.incoming = this.incoming.bind(this)
    this.status200 = this.status200.bind(this)
    this.status400 = this.status400.bind(this)
    this.status401 = this.status401.bind(this)
    this.status404 = this.status404.bind(this)
  }

  incoming (statusCode, incoming) {
    switch (statusCode) {
      case 200:
        // direct to message
        this.status200(incoming)
        break
      case 400:
        this.status400(incoming.data.message)
        break
      case 401:
        this.status401(incoming.message)
        break
      case 403:
        this.status403('File too large')
        break
      case 404:
        this.status404(incoming.data.message)
        break
      default:
      // code block
    }
  }

  status200 (message) {
    Toastr.success(message)
  }

  status400 (message) {
    Toastr.error(message)
  }

  status401 (message) {
    Toastr.info(message)
  }

  status403 (message) {
    Toastr.error(message)
  }

  status404 (message) {
    Toastr.error(message)
  }
}
