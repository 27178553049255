// /* eslint-disable */
import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table
} from 'reactstrap'
import { PropTypes } from 'prop-types'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import Toastr from 'toastr'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDoorClosed, faEye, faPlus, faTrash, faTruck } from '@fortawesome/free-solid-svg-icons'
import ValidationAuthorized from 'utils/ValidationAuthorized'
import Axios from 'helpers/Axios'
import AuthorizedHeader2 from 'components/Headers/AuthorizedHeader2'
import DatagridCreatedAtUpdatedAt from 'components/Utils/DatagridCreatedAtUpdatedAt'
import PaginationInfo from 'components/Utils/PaginationInfo'
import LabelApakahSudahDikirimKeUnitKearsipan from 'components/Utils/LabelApakahSudahDikirimKeUnitKearsipan'
import LabelApakahSudahLulusVerifikasi from 'components/Utils/LabelApakahSudahLulusVerifikasi'
import UsulPindahDetail from 'components/Utils/UsulPindahDetail'
import ArsipUsulPindahFilterAndSort from './ArsipUsulPindahFilterAndSort'
import LabelApakahSudahProsesPindah from 'components/Utils/LabelApakahSudahProsesPindah'

const ArsipUsulPindah = (props) => {
  ArsipUsulPindah.propTypes = {
    token: PropTypes.string.isRequired
  }

  const [state, setState] = useState({
    activePage: 1,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    pageRangeDisplayed: 5,
    pageSize: 7,
    data: [],
    filter: {},
    uidSelected: null
  })

  useEffect(() => {
    getData(1, {})
  }, [props])

  const getData = (page, filter) => {
    // console.log(filter)
    let url = `/unit-pengolah/usul-pindah?page=${page}`
    if (_.isObject(filter)) {
      // status
      if (_.isObject(filter.status)) {
        console.log(filter.status.value)
        // if (_.isEqual(filter.status.value, 'belumTerkirim')) {
        //   url += `&sudah-dikirim-ke-unitkearsipan=null`
        // }
        switch (filter.status.value) {
          case 'belumTerkirim':
            url += '&sudah-dikirim-ke-unitkearsipan=null'
            break
          case 'sudahTerkirim':
            url += '&sudah-dikirim-ke-unitkearsipan=true'
            break
          case 'belumTerverifikasi':
            url += '&sudah-dikirim-ke-unitkearsipan=true&sudah-lulus-verifikasi=null'
            break
          case 'verifikasiDisetujui':
            url += '&sudah-dikirim-ke-unitkearsipan=true&sudah-lulus-verifikasi=true'
            break
          case 'verifikasiDitolak':
            url += '&sudah-dikirim-ke-unitkearsipan=true&sudah-lulus-verifikasi=false'
            break
          case 'belumPindah':
            url += '&sudah-dikirim-ke-unitkearsipan=true&sudah-lulus-verifikasi=true&sudah-proses-pindah=null'
            break
          case 'sudahPindah':
            url += '&sudah-dikirim-ke-unitkearsipan=true&sudah-lulus-verifikasi=true&sudah-proses-pindah=true'
            break
        }
      }
      // search
      if (_.has(filter, 'search')) {
        if (!_.isEmpty(filter.search)) {
          url += `&search=${filter.search}`
        }
      }
      // orderBy
      if (_.has(filter, 'orderBy') && _.has(filter, 'orderOrientation')) {
        if (_.isObject(filter.orderBy) && _.isObject(filter.orderOrientation)) {
          url += `&sort={"${filter.orderBy.value}":"${filter.orderOrientation.value}"}`
        }
      }
      // pageSize
      if (_.has(filter, 'pageSize')) {
        if (_.isObject(filter.pageSize)) {
          setState((prevProps) => ({
            ...prevProps,
            itemsCountPerPage: filter.pageSize.value
          }))
          url += `&page-size=${filter.pageSize.value}`
        }
      } else {
        url += `&page-size=${state.pageSize}`
      }
    }
    // console.log(url)
    Axios({
      method: 'GET',
      url: url,
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          data: res.data._embedded.unit_pengolah_usul_pindah,
          activePage: res.data.page,
          itemsCountPerPage: res.data.page_size,
          totalItemsCount: res.data.total_items
        }))
      })
      .catch(err => {
        console.error('ERROR!', err)
      })
  }

  const handlePageChange = (pageNumber) => {
    setState((prevProps) => ({
      ...prevProps,
      activePage: pageNumber
    }))
    getData(pageNumber, state.filter)
  }

  const handleFilterApply = (filterAndSort) => {
    const filter = state.filter
    Object.assign(filter, filterAndSort)
    setState((prevProps) => ({
      ...prevProps,
      filter: filter
    }))
    getData(1, filter)
  }

  const onModalShow = (e) => {
    const uidSelected = e.currentTarget.getAttribute('data-value-uid')
    // console.log(uidSelected)
    setState((prevProps) => ({
      ...prevProps,
      uidSelected
    }))
  }

  const handleApplyShowDetail = () => {
    setState((prevProps) => ({
      ...prevProps,
      uidSelected: null
    }))
  }

  const handleApplyDelete = () => {
    // Toastr.info('handleApplyDelete')
    getData(1, state.filter)
    setState((prevProps) => ({
      ...prevProps,
      uidSelected: null
    }))
  }

  return (
    <>
       {/* {console.log(state)} */}
      {_.isNull(state.uidSelected)
        ? null
        : <ShowDetail
          uid={state.uidSelected}
          token={props.token}
          onApplyShowDetail={handleApplyShowDetail}
          onApplyDelete={handleApplyDelete}
        />
      }
      <AuthorizedHeader2 icon={faTruck} data={['penyusutan', 'usul pindah']}/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <ArsipUsulPindahFilterAndSort token={props.token} onApply={handleFilterApply}/>
                  <Col>
                    <Link
                      className="btn btn-primary float-right"
                      to="/authorized/penyusutan/add/step-1/usul-pindah">
                      <FontAwesomeIcon icon={faPlus}/>
                      <span className="btn-inner--text">Tambah</span>
                    </Link>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                <tr>
                  <th width="1%">
                    Op.
                  </th>
                  <th width="1%">
                    ID
                  </th>
                  <th scope="col">
                    Judul
                  </th>
                  <th scope="col">
                    Status <br/>Kirim
                  </th>
                  <th scope="col">
                    Status <br/>Verifikasi
                  </th>
                  <th scope="col">
                    Status <br/>Proses Pindah
                  </th>
                  <th scope="col">
                    <small>Created At<br/>Updated At</small>
                  </th>
                </tr>
                </thead>
                <tbody className="list">
                {state.data.length === 0
                  ? <tr>
                    <td colSpan={5}><code>...</code></td>
                  </tr>
                  : null}
                {state.data.map((d, i) => {
                  return (
                    <tr key={i}>
                      <td className="table-actions">
                        <FontAwesomeIcon
                          style={{ cursor: 'pointer' }}
                          icon={faEye}
                          data-value-i={i}
                          data-value-uid={d.id}
                          onClick={onModalShow}
                        />
                      </td>
                      <td><code>{d.shortid}</code></td>
                      <td>{d.judul}</td>
                      <td>
                        <LabelApakahSudahDikirimKeUnitKearsipan
                          apakahSudahDikirimKeUnitKearsipan={_.isNull(d.apakahSudahDikirimKeUnitKearsipan) ? '' : d.apakahSudahDikirimKeUnitKearsipan}
                          tanggalDikirimKeUnitKearsipan={_.isNull(d.tanggalDikirimKeUnitKearsipan) ? '' : d.tanggalDikirimKeUnitKearsipan}/>
                      </td>
                      <td>
                        <LabelApakahSudahLulusVerifikasi
                          apakahSudahLulusVerifikasi={_.isNull(d.apakahSudahLulusVerifikasi) ? '' : d.apakahSudahLulusVerifikasi}
                          tanggalVerifikasi={_.isNull(d.tanggalVerifikasi) ? '' : d.tanggalVerifikasi}
                          alasanVerifikasiDitolak={_.isNull(d.alasanVerifikasiDitolak) ? '' : d.alasanVerifikasiDitolak}
                        />
                      </td>
                      <td>
                        <LabelApakahSudahProsesPindah
                          apakahSudahProsesPindah={_.isNull(d.apakahSudahProsesPindah) ? '' : d.apakahSudahProsesPindah}
                          tanggalProsesPindah={_.isNull(d.tanggalProsesPindah) ? '' : d.tanggalProsesPindah} />
                      </td>
                      <td><DatagridCreatedAtUpdatedAt createdAt={d.createdAt} updatedAt={d.updatedAt}/></td>
                    </tr>
                  )
                })}
                </tbody>
              </Table>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    innerClass="pagination justify-content-end mb-0"
                    itemClass="page-item"
                    linkClass="page-link"
                    prevPageText="⟨"
                    nextPageText="⟩"
                    firstPageText="«"
                    lastPageText="»"
                    activePage={state.activePage}
                    itemsCountPerPage={state.itemsCountPerPage}
                    totalItemsCount={state.totalItemsCount}
                    pageRangeDisplayed={state.pageRangeDisplayed}
                    onChange={handlePageChange}
                  />
                  <PaginationInfo
                    activePage={state.activePage}
                    totalItemsCount={state.totalItemsCount}
                    itemsCountPerPage={state.itemsCountPerPage}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default ValidationAuthorized(ArsipUsulPindah)

const ShowDetail = ({ uid, token, onApplyShowDetail, onApplyDelete }) => {
  ShowDetail.propTypes = {
    uid: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    onApplyShowDetail: PropTypes.func,
    onApplyDelete: PropTypes.func
  }

  const [state, setState] = useState({
    isOpen: true,
    data: null
  })

  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    Axios.get(`/unit-pengolah/usul-pindah/${uid}?include-daftar-berkas=true`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setState((prevProps) => ({
          ...prevProps,
          data: response.data
        }))
      })
      .catch(error => {
        console.error(error)
      })
  }

  const toggleView = () => {
    setState((prevProps) => ({
      ...prevProps,
      isOpen: !state.isOpen
    }))
    onApplyShowDetail()
  }

  const onClickDelete = () => {
    Axios({
      headers: {
        Authorization: `Bearer ${token}`
      },
      method: 'DELETE',
      url: `/unit-pengolah/usul-pindah/${state.data.id}`
    })
      .then((response) => {
        Toastr.success('Usul pindah dihapus...')
        setState((prevProps) => ({
          ...prevProps,
          isOpen: false
        }))
        onApplyDelete()
      })
      .catch(() => {
        Toastr.error('Gagal!')
      })
  }

  if (_.isNull(state.data)) {
    return null
  }

  return (
    <>
      {/* {console.log(state)} */}
      <Modal size={'lg'} isOpen={state.isOpen}>
        <ModalHeader>Usul Pindah Detail</ModalHeader>
        <ModalBody>
          <UsulPindahDetail token={token} data={state.data}/>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={
              !_.isNull(state.data.apakahSudahDikirimKeUnitKearsipan)
            }
            onClick={onClickDelete}
            color="danger"><FontAwesomeIcon icon={faTrash}/> Hapus</Button>
          <Button
            onClick={toggleView}
            color="primary"><FontAwesomeIcon icon={faDoorClosed}/> Tutup</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}
