// /* eslint-disable */
import React from 'react'
import { PropTypes } from 'prop-types'
import { Breadcrumb, BreadcrumbItem, Col, Container, Row } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const AuthorizedHeader2 = (props) => {
  AuthorizedHeader2.propTypes = {
    icon: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired
  }
  return (
    <>
      <div className="header header-dark bg-success pb-6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="12">
                <Breadcrumb
                  className="text-white d-none d-md-inline-block ml-lg-4" listClassName="breadcrumb-links">
                  <BreadcrumbItem>
                    <FontAwesomeIcon icon={props.icon}/>
                  </BreadcrumbItem>
                  {props.data.map((d, i) => {
                    return (
                      <BreadcrumbItem
                        className="text-white text-capitalize"
                        key={i}>
                        {d}
                      </BreadcrumbItem>
                    )
                  })}
                </Breadcrumb>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  )
}

export default AuthorizedHeader2
