// /* eslint-disable */
import React from 'react'
import { PropTypes } from 'prop-types'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'reactstrap'
import DatetimeFormatV2 from './DatetimeFormatV2'

const LabelApakahSudahProsesPindah = (props) => {
  LabelApakahSudahProsesPindah.propTypes = {
    apakahSudahProsesPindah: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.bool
    ]),
    tanggalProsesPindah: PropTypes.string.isRequired
  }

  if (_.isString(props.apakahSudahProsesPindah)) {
    return <FontAwesomeIcon color="red" icon={faHourglassHalf}/>
  }

  if (props.apakahSudahProsesPindah) {
    return (
      <>
        <Button className="btn-sm text-uppercase" color="success">disetujui</Button> <br/>
        <em><DatetimeFormatV2 value={props.tanggalProsesPindah}/></em>
      </>
    )
  } else {
    return (
      <></>
    )
  }
}

export default LabelApakahSudahProsesPindah
