import React, { useEffect, useState } from 'react'
import { Card, CardFooter, CardHeader, Col, Container, Row } from 'reactstrap'
import { faFile } from '@fortawesome/free-solid-svg-icons'
import AuthorizedHeader2 from 'components/Headers/AuthorizedHeader2'

const Blank = (props) => {
  const [state, setState] = useState({
    data: null
  })

  useEffect(() => {
    console.log(props)

    setState((prevProps) => ({
      ...prevProps,
      data: 'Blank Page'
    }))
  }, [props])

  return (
    <>
      <AuthorizedHeader2 icon={faFile} data={['menu', 'blank']}/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardHeader className="border-0">
                <Row>
                  <Col>
                    {state.data}
                  </Col>
                </Row>
              </CardHeader>
              <CardFooter className="py-4">
                Footer
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}
export default Blank
