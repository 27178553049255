// /* eslint-disable */
import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table
} from 'reactstrap'
import { PropTypes } from 'prop-types'
import _, { debounce } from 'lodash'
import Pagination from 'react-js-pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDoorClosed, faEye, faSearch, faTruck } from '@fortawesome/free-solid-svg-icons'
import ValidationAuthorized from 'utils/ValidationAuthorized'
import AuthorizedHeader2 from 'components/Headers/AuthorizedHeader2'
import Axios from 'helpers/Axios'
import PaginationInfo from 'components/Utils/PaginationInfo'
import DatagridCreatedAtUpdatedAt from 'components/Utils/DatagridCreatedAtUpdatedAt'
import ArsipSudahPindahDetail from 'components/Utils/ArsipSudahPindahDetail'
import DatetimeFormatV2 from 'components/Utils/DatetimeFormatV2'

const DaftarBerkasSudahPindah = (props) => {
  DaftarBerkasSudahPindah.propTypes = {
    token: PropTypes.string.isRequired
  }

  const [state, setState] = useState({
    activePage: 1,
    itemsCountPerPage: 0,
    totalItemsCount: 0,
    pageRangeDisplayed: 5,
    pageSize: 7,
    data: [],
    search: '',
    filter: {},
    uidSelected: null
  })

  useEffect(() => {
    // console.log('useEffect first load')
    getData(props.token, 1, null)
  }, [props])

  const getData = (token, page, filter) => {
    // console.log(filter)
    let url = `/unit-pengolah/daftar-berkas?sudah-proses-pindah=true&page=${page}`
    if (_.isObject(filter)) {
      // search
      if (_.has(filter, 'search')) {
        if (!_.isEmpty(filter.search)) {
          url += `&search=${filter.search}`
        }
      }
      // orderBy
      if (_.has(filter, 'orderBy') && _.has(filter, 'orderOrientation')) {
        if (_.isObject(filter.orderBy) && _.isObject(filter.orderOrientation)) {
          url += `&sort={"${filter.orderBy.value}":"${filter.orderOrientation.value}"}`
        }
      }
      // pageSize
      if (_.has(filter, 'pageSize')) {
        if (_.isObject(filter.pageSize)) {
          setState((prevProps) => ({
            ...prevProps,
            itemsCountPerPage: filter.pageSize.value
          }))
          url += `&page-size=${filter.pageSize.value}`
        }
      } else {
        url += `&page-size=${state.pageSize}`
      }
    }
    // console.log(url)
    Axios({
      method: 'GET',
      url: url,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        // console.log(res.data);
        setState((prevProps) => ({
          ...prevProps,
          data: res.data._embedded.unit_pengolah_daftar_berkas,
          activePage: res.data.page,
          itemsCountPerPage: res.data.page_size,
          totalItemsCount: res.data.total_items
        }))
      })
      .catch(err => {
        console.error('ERROR!', err)
      })
  }

  const handleInputChangeSearch = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      search: obj.target.value,
      filter: { search: obj.target.value }
    }))
    const debouncedSave = debounce(() => getData(props.token, 1, { search: obj.target.value }), 1000)
    debouncedSave()
  }

  const handlePageChange = (pageNumber) => {
    setState((prevProps) => ({
      ...prevProps,
      activePage: pageNumber
    }))
    getData(props.token, pageNumber, state.filter)
  }

  const onModalShow = (e) => {
    const uidSelected = e.currentTarget.getAttribute('data-value-uid')
    // console.log(uidSelected)
    setState((prevProps) => ({
      ...prevProps,
      uidSelected
    }))
  }

  const handleApplyShowDetail = () => {
    setState((prevProps) => ({
      ...prevProps,
      uidSelected: null
    }))
  }

  // const handleApplyDelete = () => {
  //   // Toastr.info('handleApplyDelete')
  //   getData(1, state.filter)
  //   setState((prevProps) => ({
  //     ...prevProps,
  //     uidSelected: null
  //   }))
  // }

  return (
        <>
            {/* {console.log(state)} */}

            {_.isNull(state.uidSelected)
              ? null
              : <ShowDetail
                    uid={state.uidSelected}
                    token={props.token}
                    onApplyShowDetail={handleApplyShowDetail}
                    // onApplyDelete={handleApplyDelete}
                />
            }
            <AuthorizedHeader2 icon={faTruck} data={['penyusutan', 'arsip sudah pindah (daftar berkas)']}/>
            <Container className="mt--6" fluid>
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row>
                                    <Col>
                                        <Form className="navbar-search form-inline mr-sm-3">
                                            <FormGroup className="mb-0">
                                                <InputGroup className="input-group input-group-merge">
                                                    <InputGroupAddon addonType="prepend">
                                                        <InputGroupText>
                                                            <FontAwesomeIcon icon={faSearch}/>
                                                        </InputGroupText>
                                                    </InputGroupAddon>
                                                    <Input
                                                        placeholder="Search"
                                                        type="text"
                                                        value={state.search}
                                                        onChange={handleInputChangeSearch}
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                <tr>
                                    <th width="1%">
                                        Op.
                                    </th>
                                    <th scope="col">
                                        Nomor<br/>Folder
                                    </th>
                                    <th scope="col">
                                        Judul
                                    </th>
                                    <th scope="col">
                                        Kode<br/>Klasifikasi
                                    </th>
                                    <th scope="col">
                                        Tanggal<br/>Proses Pindah
                                    </th>
                                    <th scope="col">
                                        <small>Created At<br/>Updated At</small>
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="list">
                                {state.data.length === 0
                                  ? <tr>
                                        <td colSpan={5}><code>...</code></td>
                                    </tr>
                                  : null}
                                {state.data.map((d, i) => {
                                  return (
                                        <tr key={i}>
                                            <td>
                                                {/* <FontAwesomeIcon icon={faEye}/> */}
                                                <FontAwesomeIcon
                                                    style={{ cursor: 'pointer' }}
                                                    icon={faEye}
                                                    data-value-i={i}
                                                    data-value-uid={d.id}
                                                    onClick={onModalShow}
                                                />
                                            </td>
                                            <td>{d.nomorFolder}</td>
                                            <td>{d.judul}</td>
                                            <td>{_.isNull(d.jraKlasifikasi) ? null : d.jraKlasifikasi.code}</td>
                                            <td><DatetimeFormatV2 value={d.tanggalProsesPindah}/></td>
                                            <td><DatagridCreatedAtUpdatedAt createdAt={d.createdAt}
                                                                            updatedAt={d.updatedAt}/></td>
                                        </tr>
                                  )
                                })}
                                </tbody>
                            </Table>
                            <CardFooter className="py-4">
                                <nav aria-label="...">
                                    <Pagination
                                        innerClass="pagination justify-content-end mb-0"
                                        itemClass="page-item"
                                        linkClass="page-link"
                                        prevPageText="⟨"
                                        nextPageText="⟩"
                                        firstPageText="«"
                                        lastPageText="»"
                                        activePage={state.activePage}
                                        itemsCountPerPage={state.itemsCountPerPage}
                                        totalItemsCount={state.totalItemsCount}
                                        pageRangeDisplayed={state.pageRangeDisplayed}
                                        onChange={handlePageChange}
                                    />
                                    <PaginationInfo
                                        activePage={state.activePage}
                                        totalItemsCount={state.totalItemsCount}
                                        itemsCountPerPage={state.itemsCountPerPage}
                                    />
                                </nav>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
  )
}

export default ValidationAuthorized(DaftarBerkasSudahPindah)

const ShowDetail = ({ uid, token, onApplyShowDetail }) => {
  ShowDetail.propTypes = {
    uid: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
    onApplyShowDetail: PropTypes.func
  }

  const [state, setState] = useState({
    isOpen: true,
    data: null
  })

  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    Axios.get(`/unit-pengolah/daftar-berkas/${uid}?include-daftar-berkas=true`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setState((prevProps) => ({
          ...prevProps,
          data: response.data
        }))
      })
      .catch(error => {
        console.error(error)
      })
  }

  const toggleView = () => {
    setState((prevProps) => ({
      ...prevProps,
      isOpen: !state.isOpen
    }))
    onApplyShowDetail()
  }

  if (_.isNull(state.data)) {
    return null
  }

  return (
        <>
            {/* {console.log(state)} */}
            <Modal size={'lg'} isOpen={state.isOpen}>
                <ModalHeader>Arsip Sudah Pindah</ModalHeader>
                <ModalBody>
                    <ArsipSudahPindahDetail token={token} data={state.data}/>
                </ModalBody>
                <ModalFooter>
                    <Button
                        onClick={toggleView}
                        color="primary"><FontAwesomeIcon icon={faDoorClosed}/> Tutup</Button>
                </ModalFooter>
            </Modal>
        </>
  )
}
