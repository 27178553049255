import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from 'reactstrap'
import Toastr from 'toastr'
import { faKey, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Redirect } from 'react-router-dom'
import AuthHeader from 'components/Headers/AuthHeader'
import AuthService from 'utils/AuthService'

const auth = new AuthService()

const Login = () => {
  const [state, setState] = useState({
    isAuthValid: false,
    username: '',
    password: ''
  })

  useEffect(() => {
    // document.body.classList.add('bg-default')
  }, [])

  const handleChangeUsername = (event) => {
    setState((prevProps) => ({
      ...prevProps,
      username: event.target.value
    }))
  }

  const handleChangePassword = (event) => {
    setState((prevProps) => ({
      ...prevProps,
      password: event.target.value
    }))
  }

  const handleButtonSubmit = () => {
    Toastr.info('Pengecekan....')
    // console.log(state)
    auth
      .login(state.username, state.password)
      .then(res => {
        // console.log(res)
        if (res.isActive && res.isUnitPengolah) {
          Toastr.success('Login sukses!')
          setState((prevProps) => ({
            ...prevProps,
            isAuthValid: true
          }))
        } else {
          auth.logout()
          Toastr.error('Login gagal!')
        }
      })
      // .catch(error => console.error(error))
      .catch(() => Toastr.error('Gagal! Periksa kembali username & password'))
  }

  return (
    <>
      {/* {console.log(state)} */}
      {state.isAuthValid ? <Redirect to={'/authorized/dashboard/rekapitulasi'}/> : null}
      <AuthHeader
        title="Login Unit Pengolah"
        lead=""
      />
      <Container className="mt--8 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="7">
            <Card className="bg-secondary border-0 mb-0">
              <CardBody className="px-lg-5 py-lg-5">
                <div className="text-center text-light mb-4">
                  <h3 className="text-uppercase">unit pengolah</h3>
                </div>
                <Form role="form">
                  <FormGroup>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <FontAwesomeIcon icon={faUser}/>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Username"
                        type="text"
                        value={state.username}
                        onChange={handleChangeUsername}
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-merge input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <FontAwesomeIcon icon={faKey}/>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        value={state.password}
                        onChange={handleChangePassword}
                      />
                    </InputGroup>
                  </FormGroup>
                  <div className="text-center">
                    <Button
                      onClick={handleButtonSubmit}
                      className="my-4" color="info" type="button">
                      Masuk
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col lg="5" md="5">
            <img
              className="img-fluid"
              alt="Aplikasi Manajemen Rekod"
              src={require('assets/img/login-image.png').default}/>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Login
