// /* eslint-disable */
import React from 'react'
import { Card, Col, Container, Row } from 'reactstrap'

const LoadingContent = () => {
  return (
    <>
      <Container fluid>
        <Row className="align-items-center py-4">
          <Col>
            <Card className="bg-gradient-white shadow" style={{ paddingLeft: 20 }}>
              Loading...
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default LoadingContent
