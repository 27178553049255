// /* eslint-disable */
import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  Table
} from 'reactstrap'
import Toastr from 'toastr'
import { Redirect } from 'react-router-dom'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faUpload } from '@fortawesome/free-solid-svg-icons'
import ValidationAuthorized from 'utils/ValidationAuthorized'
import Axios from 'helpers/Axios'
import AuthorizedHeader from 'components/Headers/AuthorizedHeader'
import LoadingContent from 'components/Utils/LoadingContent'
import UsulPindahSuratUsulan from 'components/Utils/UsulPindahSuratUsulan'

const UsulPindahFormStep3 = (props) => {
  UsulPindahFormStep3.propTypes = {
    token: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired
  }

  const [state, setState] = useState({
    isUploadSuratUsulan: false,
    isKirimKeUnitKearsipan: false,
    data: null,
    file: null,
    isFileValid: false
  })

  useEffect(() => {
    if (!_.isUndefined(props.match.params.uid)) {
      getData(props.match.params.uid)
    }
  }, [props])

  const getData = (id) => {
    Axios.get(`/unit-pengolah/usul-pindah/${id}?include-daftar-berkas=true`, {
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    })
      .then(response => {
        setState((prevProps) => ({
          ...prevProps,
          data: response.data
        }))
      })
      .catch(error => {
        console.error(error)
      })
  }

  const handleChangeFile = (getFile) => {
    if (_.isObject(getFile)) {
      if (getFile.target.files.length > 0) {
        const file = getFile.target.files[0]
        let isFileValid = false
        if (_.isEqual(file.type, 'application/pdf') && (file.size / 1024 / 1024) < 100) {
          isFileValid = true
        }
        setState((prevProps) => ({
          ...prevProps,
          file,
          isFileValid
        }))
      }
    }
  }

  const onSubmitUploadSuratUsulan = () => {
    const formData = new FormData()
    formData.append('usulPindahUuid', state.data.id)
    formData.append('suratUsulanFile', state.file, state.file.name)

    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: 'POST',
      url: '/unit-pengolah/upload/file/usul-pindah/surat-usulan',
      data: formData
    })
      .then((response) => {
        Toastr.success('Surat Usulan diunggah...')
        setState((prevProps) => ({
          ...prevProps,
          isUploadSuratUsulan: true,
          file: null,
          isFileValid: null
        }))
        getData(props.match.params.uid)
      })
      .catch(err => {
        Toastr.error('Failed!')
        if (_.isEqual(err.response.status, 409)) {
          Toastr.warning('Conflict!')
        }
      })
  }

  const onSubmitKirimKeUnitKearsipan = () => {
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: 'POST',
      url: '/unit-pengolah/usul-pindah/kirim-usulan',
      data: {
        usulPindahUuid: state.data.id
      }
    })
      .then((response) => {
        Toastr.success('Usulan dikirim...')
        setState((prevProps) => ({
          ...prevProps,
          isKirimKeUnitKearsipan: true
        }))
        getData(props.match.params.uid)
      })
      .catch(() => {
        Toastr.error('Failed!')
      })
  }

  if (_.isNull(state.data)) {
    return <LoadingContent/>
  }

  if (state.isKirimKeUnitKearsipan) {
    return <Redirect to={'/authorized/penyusutan/usul-pindah'}/>
  }

  return (
    <>
      {console.log(state)}
      <AuthorizedHeader name="Penyusutan" parentName="Usul Pindah > Langkah 2"/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h1 className="text-black-50 text-underline text-capitalize">langkah #3, unggah surat usulan</h1>
              </CardHeader>
              <CardBody>
                <Form>
                  <FormGroup className="row pl-lg-4">
                    <Label md="1">
                      ID
                    </Label>
                    <Col md="8">
                      <Input
                        readOnly={true}
                        value={state.data.shortid}
                        placeholder="ID"
                        type="text"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row pl-lg-4">
                    <Label md="1">
                      Judul
                    </Label>
                    <Col md="8">
                      <Input
                        readOnly={true}
                        value={state.data.judul}
                        placeholder="Judul"
                        type="text"
                      />
                    </Col>
                  </FormGroup>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="9">
                        <Label>Daftar Berkas</Label>
                        <FormText>* Daftar Berkas yang telah melewati masa aktif (JRA) dan tidak sedang
                          dipinjamkan</FormText>
                        <Table bordered responsive>
                          <thead className="thead-light">
                          <tr>
                            <th>Nomor folder</th>
                            <th>Nama Berkas</th>
                          </tr>
                          </thead>
                          <tbody className="list">
                          {state.data.daftarBerkas.map((d, i) => {
                            return (
                              <tr key={i}>
                                <td>{d.nomorFolder}</td>
                                <td>{d.judul}</td>
                              </tr>
                            )
                          })}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </div>
                  <p/>
                  <FormGroup className="row pl-lg-4">
                    <Label md="2">
                      Surat Usulan
                    </Label>
                    <Col md="7">
                      {_.isEmpty(state.data.suratUsulanFileName)
                        ? null
                        : <UsulPindahSuratUsulan token={props.token} data={state.data}/>}
                      <Input
                        required
                        onChange={handleChangeFile}
                        placeholder="Select File"
                        type="file"
                      />
                      <FormText>format file yang diizinkan : pdf, max 100MB</FormText>
                      <ShowFile
                        file={state.file}
                        isFileValid={state.isFileValid}
                      />
                      <Button
                        disabled={!state.isFileValid}
                        onClick={onSubmitUploadSuratUsulan}
                        color="danger"><FontAwesomeIcon icon={faUpload}/> Unggah</Button>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  disabled={!state.isUploadSuratUsulan}
                  color='primary'
                  onClick={onSubmitKirimKeUnitKearsipan}
                ><FontAwesomeIcon icon={faArrowRight}/> Kirim Ke Unit Kearsipan</Button>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default ValidationAuthorized(UsulPindahFormStep3)

const ShowFile = (props) => {
  ShowFile.propTypes = {
    file: PropTypes.object,
    isFileValid: PropTypes.bool
  }
  if (_.isNull(props.file)) {
    return <></>
  }
  return (
    <>
      <Alert color={props.isFileValid ? 'success' : 'danger'}>
        File Name : {props.file.name} <br/>
        File Size : {(props.file.size / 1024 / 1024)} MB <br/>
        {props.isFileValid ? 'File is valid' : 'File not valid'}
      </Alert>
    </>
  )
}
