// /* eslint-disable */
import React, { useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import {
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Nav,
  Navbar,
  NavItem,
  UncontrolledDropdown
} from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { Link, Redirect } from 'react-router-dom'
import AuthService from 'utils/AuthService.js'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import Toastr from 'toastr'

const auth = new AuthService()

const AuthorizedNavbar = (props) => {
  AuthorizedNavbar.defaultProps = {
    toggleSidenav: () => {
    },
    sidenavOpen: false,
    theme: 'dark'
  }
  AuthorizedNavbar.propTypes = {
    toggleSidenav: PropTypes.func,
    sidenavOpen: PropTypes.bool,
    theme: PropTypes.oneOf(['dark', 'light'])
  }

  const [state, setState] = useState({
    alert: null,
    isLogout: false
  })

  const logoutEventSweetAlert = () => {
    setState((prevProps) => ({
      ...prevProps,
      alert: (
        <ReactBSAlert
          warning
          style={{ display: 'block' }}
          title="Keluar"
          onConfirm={onConfirm}
          onCancel={onCancel}
          confirmBtnCssClass="btn-danger"
          cancelBtnBsStyle="btn-primary"
          confirmBtnText="Ya, keluar"
          cancelBtnText="Batal"
          showCancel
          btnSize=""
        >
          Anda yakin keluar dari aplikasi?
        </ReactBSAlert>
      )
    }))
  }

  const onCancel = () => {
    setState((prevProps) => ({
      ...prevProps,
      alert: null,
      isLogout: false
    }))
  }

  const onConfirm = () => {
    Toastr.warning('Session telah berakhir, anda telah keluar dari aplikasi...')
    auth.logout()
    setState((prevProps) => ({
      ...prevProps,
      isLogout: true
    }))
  }

  if (state.isLogout) {
    return <Redirect to="/public/login/authorized"/>
  }

  return (
    <>
      {state.alert}
      <Navbar
        className={classnames(
          'navbar-top navbar-expand border-bottom',
          { 'navbar-dark bg-success': props.theme === 'dark' },
          { 'navbar-light bg-secondary': props.theme === 'light' }
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={false}>
            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    'pr-3 sidenav-toggler',
                    { active: props.sidenavOpen },
                    { 'sidenav-toggler-dark': props.theme === 'dark' }
                  )}
                  onClick={props.toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line"/>
                    <i className="sidenav-toggler-line"/>
                    <i className="sidenav-toggler-line"/>
                  </div>
                </div>
              </NavItem>
            </Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <Media className="align-items-center">
                    <span className="rounded-circle">
                      <FontAwesomeIcon icon={faUser}/>
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {auth.getProfile().username}
                        | {auth.getProfile().unitKerja.name} / {auth.getProfile().unitPengolah.name}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className="btn-link">
                    <Link to={'/authorized/konfigurasi/profil'}>
                      <i className="ni ni-single-02"/> <span>Profil</span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem className="btn-link">
                    <Link to={'/authorized/konfigurasi/ganti-password'}>
                      <i className="ni ni-key-25"/> <span>Ganti Password</span>
                    </Link>
                  </DropdownItem>
                  <DropdownItem divider/>
                  <DropdownItem
                    className="btn-link"
                    href="#Logout"
                    onClick={logoutEventSweetAlert}
                  ><i className="ni ni-user-run"/><span>Keluar</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  )
}

export default AuthorizedNavbar
