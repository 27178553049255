/* eslint-disable */
import Blank from 'views/pages/Blank'

import Rekapitulasi from 'views/pages/Authorized/Beranda/Rekapitulasi'

// import DaftarBerkas from 'views/pages/Authorized/Arsip/DaftarBerkas'
import DaftarBerkasForm from 'views/pages/Authorized/Arsip/DaftarBerkasForm'
// import DaftarBerkasV2 from 'views/pages/Authorized/Arsip/DaftarBerkasV2'
import DaftarBerkasSE from 'views/pages/Authorized/Arsip/DaftarBerkasSE'
import DaftarIsiBerkas from 'views/pages/Authorized/Arsip/DaftarIsiBerkas'
import DaftarIsiFile from 'views/pages/Authorized/Arsip/DaftarIsiFile'
import DaftarIsiBerkasForm from 'views/pages/Authorized/Arsip/DaftarIsiBerkasForm'

import Peminjam from 'views/pages/Authorized/Sirkulasi/Peminjam'
import PeminjamForm from 'views/pages/Authorized/Sirkulasi/PeminjamForm'
import TransaksiPeminjaman from 'views/pages/Authorized/Sirkulasi/TransaksiPeminjaman'
import TransaksiPeminjamanForm from 'views/pages/Authorized/Sirkulasi/TransaksiPeminjamanForm'

import ArsipUsulPindah from 'views/pages/Authorized/Penyusutan/ArsipUsulPindah'
import ArsipUsulPindahForm from 'views/pages/Authorized/Penyusutan/ArsipUsulPindahForm'
import DaftarBerkasSudahPindah from 'views/pages/Authorized/Penyusutan/DaftarBerkasSudahPindah'

import UsulPindahFormStep1 from 'views/pages/Authorized/Penyusutan/UsulPindahFormStep1'
import UsulPindahFormStep2 from 'views/pages/Authorized/Penyusutan/UsulPindahFormStep2'
import UsulPindahFormStep3 from 'views/pages/Authorized/Penyusutan/UsulPindahFormStep3'

import Profile from 'views/pages/Authorized/Konfigurasi/Profile'
import GantiPassword from 'views/pages/Authorized/Konfigurasi/GantiPassword'

const routesAuthorized = [
  {
    collapse: true,
    name: 'Beranda',
    icon: 'fa fa-tachometer-alt',
    state: 'Beranda',
    views: [
      {
        path: '/beranda/rekapitulasi',
        name: 'Rekapitulasi',
        miniName: 'R',
        component: Rekapitulasi,
        layout: '/authorized'
      }
    ]
  },
  {
    collapse: true,
    name: 'Arsip',
    icon: 'fa fa-folder-open',
    state: 'Arsip',
    views: [
      {
        path: '/arsip/daftar-berkas',
        name: 'Daftar Berkas',
        miniName: 'DB',
        component: DaftarBerkasSE,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/arsip/add/daftar-berkas',
        component: DaftarBerkasForm,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/arsip/update/daftar-berkas/:uid?',
        component: DaftarBerkasForm,
        layout: '/authorized'
      },
      {
        path: '/arsip/daftar-isi-berkas',
        name: 'Daftar Isi Berkas',
        miniName: 'DIB',
        component: DaftarIsiBerkas,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/arsip/add/daftar-isi-berkas',
        component: DaftarIsiBerkasForm,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/arsip/update/daftar-isi-berkas/:uid?',
        component: DaftarIsiBerkasForm,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/arsip/file/daftar-isi-berkas/:uid?',
        component: DaftarIsiFile,
        layout: '/authorized'
      },
      {
        path: '/arsip/add/daftar-berkas',
        name: 'Tambah Berkas',
        miniName: 'TB',
        component: Blank,
        layout: '/authorized'
      },
      {
        path: '/arsip/add/daftar-isi-berkas',
        name: 'Tambah Isi Berkas',
        miniName: 'TIB',
        component: Blank,
        layout: '/authorized'
      }
    ]
  },
  {
    collapse: true,
    name: 'Sirlukasi',
    icon: 'fa fa-shopping-bag',
    state: 'Sirlukasi',
    views: [
      {
        path: '/sirlukasi/peminjam',
        name: 'Peminjam',
        miniName: 'P',
        component: Peminjam,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/add/sirlukasi/peminjam',
        component: PeminjamForm,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/update/sirlukasi/peminjam/:uid?',
        component: PeminjamForm,
        layout: '/authorized'
      },
      {
        path: '/sirlukasi/transaksi-peminjaman',
        name: 'Transaksi Peminjaman',
        miniName: 'TP',
        component: TransaksiPeminjaman,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/add/sirlukasi/transaksi-peminjaman',
        component: TransaksiPeminjamanForm,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/update/sirlukasi/transaksi-peminjaman/:uid?',
        component: TransaksiPeminjamanForm,
        layout: '/authorized'
      }
    ]
  },
  {
    collapse: true,
    name: 'Penyusutan',
    icon: 'fa fa-truck',
    state: 'Penyusutan',
    views: [
      {
        path: '/penyusutan/usul-pindah',
        name: 'Usul Pindah',
        miniName: 'UPV',
        component: ArsipUsulPindah,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/penyusutan/add/step-1/usul-pindah',
        component: UsulPindahFormStep1,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/penyusutan/add/step-2/usul-pindah/:uid?',
        component: UsulPindahFormStep2,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/penyusutan/add/step-3/usul-pindah/:uid?',
        component: UsulPindahFormStep3,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/penyusutan/detail/usul-pindah/:uid?',
        component: Blank,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/penyusutan/add/usul-pindah',
        component: ArsipUsulPindahForm,
        layout: '/authorized'
      },
      {
        hidden: true,
        path: '/penyusutan/update/usul-pindah/:uid?',
        component: ArsipUsulPindahForm,
        layout: '/authorized'
      },
      {
        path: '/penyusutan/arsip-sudah-pindah',
        name: 'Arsip Sudah Pindah',
        miniName: 'SP',
        component: DaftarBerkasSudahPindah,
        layout: '/authorized'
      }
    ]
  },
  {
    collapse: true,
    name: 'Konfigurasi',
    icon: 'fa fa-wrench',
    state: 'Konfigurasi',
    views: [
      {
        path: '/konfigurasi/profil',
        name: 'Profil',
        miniName: 'P',
        component: Profile,
        layout: '/authorized'
      },
      {
        path: '/konfigurasi/ganti-password',
        name: 'Ganti Password',
        miniName: 'GP',
        component: GantiPassword,
        layout: '/authorized'
      }
    ]
  }
]

export default routesAuthorized
