// /* eslint-disable */
import React, { useEffect, useState } from 'react'
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Table
} from 'reactstrap'
import { PropTypes } from 'prop-types'
import Axios from 'helpers/Axios'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import Pagination from 'react-js-pagination'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderOpen, faPencilAlt, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'
import ValidationAuthorized from 'utils/ValidationAuthorized'
import AuthorizedHeader2 from 'components/Headers/AuthorizedHeader2'
import PaginationInfo from 'components/Utils/PaginationInfo'
import DatagridCreatedAtUpdatedAt from 'components/Utils/DatagridCreatedAtUpdatedAt'

const DaftarBerkasSE = (props) => {
  DaftarBerkasSE.propTypes = {
    token: PropTypes.string.isRequired
  }

  const [state, setState] = useState({
    searchKeyword: '',
    searchFormat: 'explode',
    data: null,
    activePage: 1,
    itemsCountPerPage: 8,
    totalItemsCount: 1,
    pageRangeDisplayed: 10
  })

  useEffect(() => {
    getData(1)
  }, [props])

  const getData = (pageNumber) => {
    let url = '/unit-pengolah/se/daftar-berkas?'
    url += `&page=${pageNumber}`
    url += `&rows=${state.itemsCountPerPage}`

    if (!_.isEmpty(state.searchKeyword)) {
      url += `&search_keyword=${state.searchKeyword}&search_format=${state.searchFormat}`
    }
    // console.log(url);
    Axios({
      method: 'GET',
      url: url,
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    })
      .then(res => {
        // console.log(res.data);
        setState((prevProps) => ({
          ...prevProps,
          data: res.data.response.docs,
          totalItemsCount: res.data.response.numFound,
          activePage: pageNumber
        }))
      })
      .catch(err => {
        console.error('ERROR!', err)
      })
  }

  const onChangeSearchFormat = (e) => {
    setState((prevProps) => ({
      ...prevProps,
      searchFormat: e.target.value
    }))
  }

  const onChangeSearchKeyword = (obj) => {
    const searchKeyword = obj.target.value
    setState((prevProps) => ({
      ...prevProps,
      searchKeyword
    }))
  }

  const onKeyDownSearchKeyword = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      // console.log("en Enter");
      setState((prevProps) => ({
        ...prevProps,
        data: null
      }))
      getData(1)
    }
  }

  const handlePageChange = (pageNumber) => {
    setState((prevProps) => ({
      ...prevProps,
      activePage: pageNumber
    }))
    getData(pageNumber)
  }

  const onClick = (e) => {
    e.preventDefault()
    setState((prevProps) => ({
      ...prevProps,
      data: []
    }))
    getData(1)
  }

  return (
    <>
      {/* {console.log(state)} */}
      <AuthorizedHeader2 icon={faFolderOpen} data={['arsip', 'daftar berkas']}/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col>
                    <Form className="navbar-search form-inline mr-sm-3">
                      <FormGroup className="mb-0">
                        <InputGroup className="input-group input-group-merge">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <FontAwesomeIcon icon={faSearch}/>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Search"
                            value={state.qValue}
                            onChange={onChangeSearchKeyword}
                            onKeyDown={onKeyDownSearchKeyword}
                          />
                        </InputGroup>
                      </FormGroup>
                    </Form>
                  </Col>
                  <Col>
                    <ButtonGroup className="float-right">
                      <Button color="default" onClick={onClick}>
                        <FontAwesomeIcon icon={faSearch}/> Cari
                      </Button>
                      <Link
                        className="btn btn-primary float-right"
                        to="/authorized/arsip/add/daftar-berkas">
                        <FontAwesomeIcon icon={faPlus}/>
                        <span className="btn-inner--text">Tambah</span>
                      </Link>
                    </ButtonGroup>
                  </Col>
                </Row>
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      value={'explode'}
                      checked={state.searchFormat === 'explode'}
                      onChange={onChangeSearchFormat}
                    />{' '}
                    terkandung
                  </label>{' '}
                  <label>
                    <input
                      type="radio"
                      value={'concat'}
                      checked={state.searchFormat === 'concat'}
                      onChange={onChangeSearchFormat}
                    />{' '}
                    gabungkan
                  </label>
                </div>

              </CardHeader>
              <CardBody>
                {_.isNull(state.data) ? 'Loading...' : ''}
                {_.isArray(state.data)
                  ? <>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                    <tr>
                      <th width="1%">
                        Op.
                      </th>
                      <th scope="col">
                        Nomor<br/>Folder
                      </th>
                      <th scope="col">
                        Judul
                      </th>
                      <th scope="col">
                        <small>Created At<br/>Updated At</small>
                      </th>
                    </tr>
                    </thead>
                    <tbody className="list">
                    {state.data.length === 0
                      ? <tr>
                        <td colSpan={5}><code>...</code></td>
                      </tr>
                      : null}
                    {state.data.map((d, i) => {
                      return (
                        <tr
                          key={i}
                          // className={_.isBoolean(d.isRetensiAktifExpired) ? isRetensiAktifExpired(d.isRetensiAktifExpired) : 'text-default'}
                        >
                          <td>
                            <Link
                              to={`/authorized/arsip/update/daftar-berkas/${d.id}`}>
                              <FontAwesomeIcon icon={faPencilAlt}/>
                            </Link>
                          </td>
                          <td>{d.nomorFolder}</td>
                          <td>{d.judul}</td>
                          <td><DatagridCreatedAtUpdatedAt createdAt={d.createdAt} updatedAt={d.updatedAt}/></td>
                        </tr>
                      )
                    })}
                    </tbody>
                  </Table>
                </>
                  : null}
              </CardBody>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <Pagination
                    innerClass="pagination justify-content-end mb-0"
                    itemClass="page-item"
                    linkClass="page-link"
                    prevPageText="⟨"
                    nextPageText="⟩"
                    activePage={state.activePage}
                    itemsCountPerPage={8}
                    totalItemsCount={state.totalItemsCount}
                    pageRangeDisplayed={state.pageRangeDisplayed}
                    onChange={handlePageChange}
                  />
                  <PaginationInfo
                    activePage={state.activePage}
                    itemsCountPerPage={state.itemsCountPerPage}
                    totalItemsCount={state.totalItemsCount}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default ValidationAuthorized(DaftarBerkasSE)
