// /* eslint-disable */
import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap'
import Select from 'react-select'
import Toastr from 'toastr'
import { Link, Redirect } from 'react-router-dom'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faFile, faFolderOpen, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import ValidationAuthorized from 'utils/ValidationAuthorized'
import Axios from 'helpers/Axios'
import AuthorizedHeader2 from 'components/Headers/AuthorizedHeader2'

const DaftarIsiBerkasForm = (props) => {
  DaftarIsiBerkasForm.propTypes = {
    token: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired
  }

  const [state, setState] = useState({
    isSavedData: false,

    uid: null,
    berkas: null,
    nomorDokumen: '',
    tanggalDokumen: '',
    judul: '',
    subyek: '',
    deskripsi: '',
    tingkatPerkembangan: null,

    daftarBerkasOptions: [],
    tingkatPerkembanganOptions: [],

    isModalOpen: false
  })

  useEffect(() => {
    getDaftarBerkas(props.token)
    getTingkatPerkembangan(props.token)
    if (!_.isUndefined(props.match.params.uid)) {
      getData(props.token, props.match.params.uid)
    }
  }, [props])

  const getDaftarBerkas = (token) => {
    Axios.get('/unit-pengolah/daftar-berkas-list?is-active=true&sudah-proses-pindah=null&format=select_options', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          daftarBerkasOptions: res.data
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  const getTingkatPerkembangan = (token) => {
    Axios.get('/tingkat-perkembangan-list?is-active=true&format=select_options', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          tingkatPerkembanganOptions: res.data
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  const getData = (token, id) => {
    Axios.get(`/unit-pengolah/isi-berkas/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          // isUpdate: true,
          uid: res.data.id,
          judul: res.data.judul,
          nomorDokumen: res.data.nomorDokumen,
          subyek: res.data.subyek,
          berkas: _.isNull(res.data.daftarBerkas)
            ? { value: '', label: '' }
            : {
                value: res.data.daftarBerkas.id,
                label: `[${res.data.daftarBerkas.nomorFolder}] ${res.data.daftarBerkas.judul}`
              },
          tingkatPerkembangan: _.isNull(res.data.tingkatPerkembangan)
            ? { value: '', label: '' }
            : {
                value: res.data.tingkatPerkembangan.id,
                label: `[${res.data.tingkatPerkembangan.code}] ${res.data.tingkatPerkembangan.name}`
              }
        }))

        if (_.has(res.data, 'tanggalDokumen')) {
          setState((prevProps) => ({
            ...prevProps,
            tanggalDokumen: _.isNull(res.data.tanggalDokumen) ? '' : res.data.tanggalDokumen.substring(0, 10)
          }))
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  const handleChangeBerkas = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      berkas: obj
    }))
  }

  const handleChangeNomorDokumen = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      nomorDokumen: obj.target.value
    }))
  }

  const handleChangeJudul = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      judul: obj.target.value
    }))
  }

  const handleChangeSubyek = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      subyek: obj.target.value
    }))
  }

  const handleChangeTanggalDokumen = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      tanggalDokumen: obj.target.value
    }))
  }

  const handleChangeTingkatPerkembangan = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      tingkatPerkembangan: obj
    }))
  }

  const onSubmit = () => {
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: _.isNull(state.uid) ? 'POST' : 'PATCH',
      url: _.isNull(state.uid) ? '/unit-pengolah/isi-berkas' : `/unit-pengolah/isi-berkas/${state.uid}`,
      data: {
        nomorDokumen: state.nomorDokumen,
        judul: state.judul,
        subyek: state.subyek,
        tingkatPerkembangan: _.isNull(state.tingkatPerkembangan) ? '' : state.tingkatPerkembangan.value,
        daftarBerkas: _.isNull(state.berkas) ? '' : state.berkas.value,
        tanggalDokumen: state.tanggalDokumen
      }
    })
      .then(() => {
        // console.log(res.data)
        Toastr.success('Data berhasil disimpan!')
        setState((prevProps) => ({
          ...prevProps,
          isSavedData: true
        }))
      })
      .catch(err => {
        Toastr.error('Failed!')
        if (_.isEqual(err.response.status, 409)) {
          Toastr.warning('Conflict!')
        }
      })
  }

  const toggleModal = () => {
    setState((prevProps) => ({
      ...prevProps,
      isModalOpen: !state.isModalOpen
    }))
  }

  const onDelete = () => {
    Toastr.info('Menghapus data...')
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: 'DELETE',
      url: `/unit-pengolah/isi-berkas/${state.uid}`,
      data: {}
    })
      .then(() => {
        // console.log(res.data);
        Toastr.success('Data berhasil dihapus!')
        setState((prevProps) => ({
          ...prevProps,
          isSavedData: true
        }))
      })
      .catch(err => {
        console.error('ERROR!', err)
        Toastr.error('Failed!')
      })
  }

  if (state.isSavedData) {
    return <Redirect to={'/authorized/arsip/daftar-isi-berkas'}/>
  }

  return (
    <>
       {/* {console.log(props.match.url)} */}
      <AuthorizedHeader2 icon={faFolderOpen} data={['arsip', 'daftar isi berkas']}/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="bg-gradient-white shadow">
              <CardHeader className="bg-white border-0">
                <Link
                  className="btn btn-primary"
                  to="/authorized/arsip/daftar-isi-berkas">
                  <FontAwesomeIcon icon={faBars}/>
                  <span className="btn-inner--text">Lihat Data</span>
                </Link>
                {
                  _.isEqual(props.match.url, '/authorized/arsip/add/daftar-isi-berkas')
                    ? null
                    : <>
                      <Link
                        className="btn btn-secondary"
                        to={`/authorized/arsip/file/daftar-isi-berkas/${props.match.params.uid}`}>
                        <FontAwesomeIcon icon={faFile}/>
                        <span className="btn-inner--text">File</span>
                      </Link>
                    </>
                }
              </CardHeader>
              <CardBody>
                {_.isNull(state.uid) ? null : <Alert color="warning">Anda akan mengubah data: <strong>{state.judul}</strong></Alert>}
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="9">
                        <FormGroup>
                          <Label>Berkas</Label>
                          <Select
                            value={state.berkas}
                            onChange={handleChangeBerkas}
                            options={state.daftarBerkasOptions}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="9">
                        <FormGroup>
                          <Label>Judul</Label>
                          <Input
                            value={state.judul}
                            onChange={handleChangeJudul}
                            placeholder="Judul"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <Label>Nomor dokumen</Label>
                          <Input
                            value={state.nomorDokumen}
                            onChange={handleChangeNomorDokumen}
                            placeholder="Nomor dokumen"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Label>Subyek</Label>
                          <Input
                            value={state.subyek}
                            onChange={handleChangeSubyek}
                            placeholder="Subyek"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="3">
                        <FormGroup>
                          <Label>Tanggal Dokumen</Label>
                          <Input
                            value={state.tanggalDokumen}
                            onChange={handleChangeTanggalDokumen}
                            id="example-date-input"
                            type="date"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <Label>Tingkat Perkembangan</Label>
                          <Select
                            value={state.tingkatPerkembangan}
                            onChange={handleChangeTingkatPerkembangan}
                            options={state.tingkatPerkembanganOptions}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  disabled={(
                    _.isNull(state.berkas) ||
                    _.isEqual(state.judul, '')
                  )}
                  color='primary'
                  onClick={onSubmit}
                ><FontAwesomeIcon icon={faSave}/> Simpan</Button>
                {_.isNull(state.uid)
                  ? null
                  : <Button
                    color='danger'
                    onClick={toggleModal}
                  ><FontAwesomeIcon icon={faTrash}/> Hapus</Button>}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal
        isOpen={state.isModalOpen}
        backdrop='static' centered={true}
        toggle={toggleModal}
      >
        <ModalHeader
          toggle={toggleModal}
        >Konfirmasi Hapus Data</ModalHeader>
        <ModalBody>
          PERHATIAN! Anda akan menghapus data ini, data yang terhapus tidak bisa dikembalikan. Apakah Anda yakin ingin
          menghapus data ini?
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={onDelete}
          >YA, HAPUS</Button>{' '}
          <Button
            color="secondary"
            onClick={toggleModal}
          >Batal</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ValidationAuthorized(DaftarIsiBerkasForm)
