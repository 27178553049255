// /* eslint-disable */
import React from 'react'
import { PropTypes } from 'prop-types'
import { Table } from 'reactstrap'
import _ from 'lodash'
import UsulPindahSuratUsulan from './UsulPindahSuratUsulan'
import LabelApakahSudahDikirimKeUnitKearsipan from './LabelApakahSudahDikirimKeUnitKearsipan'
import LabelApakahSudahLulusVerifikasi from './LabelApakahSudahLulusVerifikasi'
import LabelApakahSudahProsesPindah from './LabelApakahSudahProsesPindah'

const UsulPindahDetail = (props) => {
  UsulPindahDetail.propTypes = {
    token: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired
  }

  return (
    <>
      <Table>
        <tbody>
        <tr>
          <td className="text-right col-md-1">ID :</td>
          <td><code className="text-lg">{props.data.shortid}</code></td>
        </tr>
        <tr>
          <td className="text-right">Judul :</td>
          <td><h5>{props.data.judul}</h5></td>
        </tr>
        <tr>
          <td className="text-right">Daftar Berkas:</td>
          <td>
            {/* <ul> */}
            {/*  {props.data.daftarBerkas.map((d, i) => { */}
            {/*    return ( */}
            {/*      <li key={i}> */}
            {/*        {d.nomorFolder} - {d.judul} */}
            {/*      </li> */}
            {/*    ) */}
            {/*  })} */}
            {/* </ul> */}
            <Table bordered responsive>
              <thead className="thead-light">
              <tr>
                <th className="col-md-1">nomor <br />folder</th>
                <th className="col-md-3">nama berkas</th>
              </tr>
              </thead>
              <tbody className="list">
              {props.data.daftarBerkas.map((d, i) => {
                return (
                  <tr key={i}>
                    <td>{d.nomorFolder}</td>
                    <td>{d.judul}</td>
                  </tr>
                )
              })}
              </tbody>
            </Table>
          </td>
        </tr>
        <tr>
          <td className="text-right">Surat usulan :</td>
          <td>
            {_.isEmpty(props.data.suratUsulanFileName)
              ? null
              : <UsulPindahSuratUsulan token={props.token} data={props.data}/>
            }
          </td>
        </tr>
        <tr>
          <td className="text-right">Waktu kirim <br />ke unit kearsipan:</td>
          <td>
            <LabelApakahSudahDikirimKeUnitKearsipan
              apakahSudahDikirimKeUnitKearsipan={_.isNull(props.data.apakahSudahDikirimKeUnitKearsipan) ? '' : props.data.apakahSudahDikirimKeUnitKearsipan}
              tanggalDikirimKeUnitKearsipan={_.isNull(props.data.tanggalDikirimKeUnitKearsipan) ? '' : props.data.tanggalDikirimKeUnitKearsipan}/>
          </td>
        </tr>
        <tr>
          <td className="text-right">Verifikasi :</td>
          <td>
            <LabelApakahSudahLulusVerifikasi
              apakahSudahLulusVerifikasi={_.isNull(props.data.apakahSudahLulusVerifikasi) ? '' : props.data.apakahSudahLulusVerifikasi}
              tanggalVerifikasi={_.isNull(props.data.tanggalVerifikasi) ? '' : props.data.tanggalVerifikasi}
              alasanVerifikasiDitolak={_.isNull(props.data.alasanVerifikasiDitolak) ? '' : props.data.alasanVerifikasiDitolak}
            />
          </td>
        </tr>
        <tr>
          <td className="text-right">Proses Pindah :</td>
          <td>
            <LabelApakahSudahProsesPindah
              apakahSudahProsesPindah={_.isNull(props.data.apakahSudahProsesPindah) ? '' : props.data.apakahSudahProsesPindah}
              tanggalProsesPindah={_.isNull(props.data.tanggalProsesPindah) ? '' : props.data.tanggalProsesPindah} />
          </td>
        </tr>
        </tbody>
      </Table>
    </>
  )
}

export default UsulPindahDetail
