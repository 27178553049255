// /* eslint-disable */
import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap'
import Select from 'react-select'
import Toastr from 'toastr'
import { Link, Redirect } from 'react-router-dom'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faSave, faTrash, faHandHolding, faShoppingBag } from '@fortawesome/free-solid-svg-icons'
import ValidationAuthorized from 'utils/ValidationAuthorized'
import Axios from 'helpers/Axios'
import AuthorizedHeader2 from 'components/Headers/AuthorizedHeader2'

const TransaksiPeminjamanForm = (props) => {
  TransaksiPeminjamanForm.propTypes = {
    token: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired
  }

  const [state, setState] = useState({
    isSavedData: false,

    uid: null,
    shortid: '',
    daftarBerkas: null,
    peminjam: null,
    tanggalPeminjaman: '',
    targetTanggalPengembalian: '',
    tanggalPengembalian: '',
    catatan: '',
    apakahSudahDikembalikan: null,

    daftarBerkasOptions: [],
    peminjamOptions: [],

    isModalDeleteOpen: false,
    isModalPengembalianOpen: false
  })

  useEffect(() => {
    getDaftarBerkas()
    getPeminjam()
    if (!_.isUndefined(props.match.params.uid)) {
      getData(props.match.params.uid)
    }
  }, [props])

  const getDaftarBerkas = () => {
    Axios.get('/unit-pengolah/daftar-berkas-list?is-active=true&format=select_options&sedang-dipinjam=false', {
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          daftarBerkasOptions: res.data
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  const getPeminjam = () => {
    Axios.get('/unit-pengolah/peminjam-list?is-active=true&format=select_options', {
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          peminjamOptions: res.data
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  const getData = (id) => {
    Axios.get(`/unit-pengolah/transaksi-peminjaman/${id}`, {
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          uid: res.data.id,
          shortid: res.data.shortid,
          catatan: res.data.catatan,
          apakahSudahDikembalikan: res.data.apakahSudahDikembalikan,
          peminjam: _.isNull(res.data.peminjam)
            ? { value: '', label: '' }
            : {
                value: res.data.peminjam.id,
                label: `[${res.data.peminjam.nip}] ${res.data.peminjam.fullName}`
              },
          daftarBerkas: _.isNull(res.data.daftarBerkas)
            ? { value: '', label: '' }
            : {
                value: res.data.daftarBerkas.id,
                label: `[${res.data.daftarBerkas.nomorFolder}] ${res.data.daftarBerkas.judul}`
              }
        }))

        if (_.has(res.data, 'tanggalPeminjaman')) {
          setState((prevProps) => ({
            ...prevProps,
            tanggalPeminjaman: _.isNull(res.data.tanggalPeminjaman) ? null : res.data.tanggalPeminjaman.substring(0, 10)
          }))
        }

        if (_.has(res.data, 'tanggalPengembalian')) {
          setState((prevProps) => ({
            ...prevProps,
            tanggalPengembalian: _.isNull(res.data.tanggalPengembalian) ? '' : res.data.tanggalPengembalian.substring(0, 10)
          }))
        }

        if (_.has(res.data, 'targetTanggalPengembalian')) {
          setState((prevProps) => ({
            ...prevProps,
            targetTanggalPengembalian: _.isNull(res.data.targetTanggalPengembalian) ? null : res.data.targetTanggalPengembalian.substring(0, 10)
          }))
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  const handleChangeDaftarBerkas = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      daftarBerkas: obj
    }))
  }

  const handleChangePeminjam = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      peminjam: obj
    }))
  }

  const handleChangeTanggalPeminjaman = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      tanggalPeminjaman: obj.target.value
    }))
  }

  const handleChangeTargetTanggalPengembalian = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      targetTanggalPengembalian: obj.target.value
    }))
  }

  const handleChangeCatatan = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      catatan: obj.target.value
    }))
  }

  const onSubmit = () => {
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: _.isNull(state.uid) ? 'POST' : 'PATCH',
      url: _.isNull(state.uid) ? '/unit-pengolah/transaksi-peminjaman' : `/unit-pengolah/transaksi-peminjaman/${state.uid}`,
      data: {
        daftarBerkas: _.isNull(state.daftarBerkas) ? '' : state.daftarBerkas.value,
        peminjam: _.isNull(state.peminjam) ? '' : state.peminjam.value,
        tanggalPeminjaman: state.tanggalPeminjaman,
        targetTanggalPengembalian: state.targetTanggalPengembalian,
        catatan: state.catatan
      }
    })
      .then(() => {
        // console.log(res.data)
        Toastr.success('Data berhasil disimpan!')
        setState((prevProps) => ({
          ...prevProps,
          isSavedData: true
        }))
      })
      .catch(err => {
        Toastr.error('Failed!')
        if (_.isEqual(err.response.status, 409)) {
          Toastr.warning('Conflict!')
        }
      })
  }

  const toggleModalDelete = () => {
    setState((prevProps) => ({
      ...prevProps,
      isModalDeleteOpen: !state.isModalDeleteOpen
    }))
  }

  const toggleModalPengembalian = () => {
    setState((prevProps) => ({
      ...prevProps,
      isModalPengembalianOpen: !state.isModalPengembalianOpen
    }))
  }

  const handleChangeTanggalPengembalian = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      tanggalPengembalian: obj.target.value
    }))
  }

  const onSubmitPengembalian = () => {
    Toastr.info('Pengembalian pinjaman...')
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: 'POST',
      url: '/unit-pengolah/sirkulasi/pengembalian-peminjaman',
      data: {
        transaksiPeminjamanUuid: state.uid,
        tanggalPengembalian: state.tanggalPengembalian
      }
    })
      .then(() => {
        Toastr.success('Pengembalian pinjaman sukses!')
        setState((prevProps) => ({
          ...prevProps,
          isSavedData: true
        }))
      })
      .catch(err => {
        console.error('ERROR!', err)
        Toastr.error('Failed!')
      })
  }

  const onSubmitDelete = () => {
    Toastr.info('Menghapus data...')
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: 'DELETE',
      url: `/unit-pengolah/transaksi-peminjaman/${state.uid}`,
      data: {}
    })
      .then(() => {
        // console.log(res.data);
        Toastr.success('Data berhasil dihapus!')
        setState((prevProps) => ({
          ...prevProps,
          isSavedData: true
        }))
      })
      .catch(err => {
        console.error('ERROR!', err)
        Toastr.error('Failed!')
      })
  }

  if (state.isSavedData) {
    return <Redirect to={'/authorized/sirlukasi/transaksi-peminjaman'}/>
  }

  return (
    <>
       {/* {console.log(state)} */}
      <AuthorizedHeader2 icon={faShoppingBag} data={['sirkulasi', 'transaksi peminjaman']}/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="bg-gradient-white shadow">
              <CardHeader className="bg-white border-0">
                <Link
                  className="btn btn-primary"
                  to="/authorized/sirlukasi/transaksi-peminjaman">
                  <FontAwesomeIcon icon={faBars}/>
                  <span className="btn-inner--text">Lihat Data</span>
                </Link>
              </CardHeader>
              <CardBody>
                {_.isNull(state.uid) ? null : <Alert color="warning">Anda akan mengubah data: <strong>{state.shortid}</strong></Alert>}
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="9">
                        <FormGroup>
                          <Label>Berkas</Label>
                          <br />
                          <Select
                            isDisabled={!_.isNull(state.uid)}
                            value={state.daftarBerkas}
                            onChange={handleChangeDaftarBerkas}
                            options={state.daftarBerkasOptions}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="9">
                        <FormGroup>
                          <Label>Peminjam</Label>
                          <Select
                            value={state.peminjam}
                            onChange={handleChangePeminjam}
                            options={state.peminjamOptions}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <Label>Tanggal Peminjaman</Label>
                          <Input
                            value={state.tanggalPeminjaman}
                            onChange={handleChangeTanggalPeminjaman}
                            type="date"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="5">
                        <FormGroup>
                          <Label>Target Tanggal Pengembalian</Label>
                          <Input
                            value={state.targetTanggalPengembalian}
                            onChange={handleChangeTargetTanggalPengembalian}
                            type="date"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  {_.isEmpty(state.tanggalPengembalian)
                    ? null
                    : <><div className="pl-lg-4">
                      <Row>
                        <Col lg="4">
                          <FormGroup>
                            <Label>Tanggal Pengembalian</Label>
                            <Input
                              defaultValue={state.tanggalPengembalian}
                              type="date"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                  </>}
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="9">
                        <FormGroup>
                          <Label>Catatan</Label>
                          <Input
                            value={state.catatan}
                            onChange={handleChangeCatatan}
                            placeholder="Catatan"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  disabled={(
                    _.isNull(state.berkas) ||
                    _.isNull(state.peminjam) ||
                    _.isEqual(state.tanggalPeminjaman, '') ||
                    !_.isEmpty(state.tanggalPengembalian) ||
                    _.isEqual(state.targetTanggalPengembalian, '')
                  )}
                  color='primary'
                  onClick={onSubmit}
                ><FontAwesomeIcon icon={faSave}/> Simpan</Button>
                {_.isString(state.uid) && _.isEmpty(state.tanggalPengembalian)
                  ? <>
                    <Button
                      color='info'
                      onClick={toggleModalPengembalian}><FontAwesomeIcon icon={faHandHolding}/> Pengembalian
                    </Button>
                  </>
                  : null}
                {_.isString(state.uid) && _.isEmpty(state.tanggalPengembalian)
                  ? <>
                    <Button
                      color='danger'
                      onClick={toggleModalDelete}><FontAwesomeIcon icon={faTrash}/> Hapus
                    </Button>
                  </>
                  : null}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal
        isOpen={state.isModalPengembalianOpen}
        toggle={toggleModalPengembalian}
        backdrop='static' centered={true} >
        <ModalHeader
          toggle={toggleModalPengembalian}>
          Konfirmasi Pengembalian Pinjaman
        </ModalHeader>
        <ModalBody>
          <Form>
            <div>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Tanggal Pengembalian</Label>
                    <Input
                      value={state.tanggalPengembalian}
                      onChange={handleChangeTanggalPengembalian}
                      type="date"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            disabled={_.isEmpty(state.tanggalPengembalian)}
            color="primary"
            onClick={onSubmitPengembalian}>Ya, Simpan</Button>{' '}
          <Button
            color="secondary"
            onClick={toggleModalPengembalian}>Batal</Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={state.isModalDeleteOpen}
        toggle={toggleModalDelete}
        backdrop='static' centered={true} >
        <ModalHeader
          toggle={toggleModalDelete}>
          Konfirmasi Hapus Data
        </ModalHeader>
        <ModalBody>
          PERHATIAN! Anda akan menghapus data ini, data yang terhapus tidak bisa dikembalikan. Apakah Anda yakin ingin
          menghapus data ini?
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={onSubmitDelete}>YA, HAPUS</Button>{' '}
          <Button
            color="secondary"
            onClick={toggleModalDelete}>Batal</Button>
        </ModalFooter>
      </Modal>

    </>
  )
}

export default ValidationAuthorized(TransaksiPeminjamanForm)
