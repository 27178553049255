// /* eslint-disable */
import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Container,
  Form,
  FormText,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table
} from 'reactstrap'
import { PropTypes } from 'prop-types'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import ReactBSAlert from 'react-bootstrap-sweetalert'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faDownload,
  faHome,
  faTrash,
  faUpload,
  faDoorClosed,
  faFolderOpen
} from '@fortawesome/free-solid-svg-icons'
import ValidationAuthorized from 'utils/ValidationAuthorized'
import AuthorizedHeader2 from 'components/Headers/AuthorizedHeader2'
import Axios from 'helpers/Axios'
import HttpResponseNotification from 'utils/HttpResponseNotification'
import ReadFilePdf from 'components/Utils/ReadFilePdf'

const httpResponseNotification = new HttpResponseNotification()

const DaftarIsiFile = (props) => {
  DaftarIsiFile.propTypes = {
    token: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired
  }

  const [state, setState] = useState({
    files: [],
    alert: null
  })

  useEffect(() => {
    if (!_.isUndefined(props.match.params.uid)) {
      getFiles(props.token, props.match.params.uid)
    }
  }, [props])

  const getFiles = (token, id) => {
    Axios.get(`/unit-pengolah/isi-berkas/${id}?include-files=TRUE`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          files: res.data.files
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  const onDelete = (e) => {
    const uidFile = e.currentTarget.getAttribute('data-value-id')
    const fileName = e.currentTarget.getAttribute('data-value-filename')

    setState((prevProps) => ({
      ...prevProps,
      alert: (
        <ReactBSAlert
          warning
          style={{
            display: 'block'
          }}
          title="Hapus"
          onConfirm={() => onConfirm(uidFile)}
          onCancel={onCancel}
          confirmBtnCssClass="btn-danger"
          cancelBtnBsStyle="btn-primary"
          confirmBtnText="Ya, hapus"
          cancelBtnText="Batal"
          showCancel
          btnSize="">
          <strong><em>{fileName}</em></strong><br/>Anda yakin ?
        </ReactBSAlert>
      )
    }))
  }

  const onCancel = () => {
    // console.log(`onCancel > ${state.uidFile}`);
    setState((prevProps) => ({
      ...prevProps,
      alert: null
    }))
  }

  const onConfirm = (uidFile) => {
    // console.log(`onConfirm > ${uidFile}`);
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: 'POST',
      url: '/unit-pengolah/isi-berkas-file/delete',
      data: {
        isiBerkasFileUuid: uidFile
      }
    })
      .then(() => {
        // console.success('success')
        setState((prevProps) => ({
          ...prevProps,
          alert: null
        }))
        getFiles(props.token, props.match.params.uid)
      })
      .catch(err => {
        console.error('ERROR!', err)
      })
  }

  const onApply = () => {
    getFiles(props.token, props.match.params.uid)
  }

  return (
    <>
      {/* {console.log(state)} */}
      {state.alert}
      <AuthorizedHeader2 icon={faFolderOpen} data={['arsip', 'daftar isi berkas', 'file']}/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="bg-gradient-white shadow">
              <CardHeader className="bg-white border-0">
                <Link
                  className="btn btn-primary"
                  to="/authorized/arsip/daftar-isi-berkas">
                  <FontAwesomeIcon icon={faBars}/>
                  <span className="btn-inner--text">Lihat Data</span>
                </Link>
                <Link
                  className="btn btn-secondary"
                  to={`/authorized/arsip/update/daftar-isi-berkas/${props.match.params.uid}`}>
                  <FontAwesomeIcon icon={faHome}/>
                  <span className="btn-inner--text">Meta Data Isi Berkas</span>
                </Link>
              </CardHeader>
              <CardBody>
                <FileUploadForm
                  token={props.token}
                  id={props.match.params.uid}
                  onApply={onApply}/>
                <Table
                  bordered={true}
                  className="align-items-center table-flush"
                  responsive={true}
                  >
                  <thead className="thead-light">
                  <tr>
                    <th width="1%">
                      Op.
                    </th>
                    <th>
                      File
                    </th>
                  </tr>
                  </thead>
                  <tbody className="list bg-gradient-lighter">
                  {state.files.length === 0
                    ? <tr>
                      <td colSpan={5}><code>...</code></td>
                    </tr>
                    : null}
                  {state.files.map((d, i) => {
                    return (
                      <tr key={i}>
                        <td className="table-actions">
                          <Link
                            to={`#${d.id}`}>
                            <FontAwesomeIcon
                              icon={faTrash}
                              data-value-id={d.id}
                              data-value-filename={d.fileName}
                              onClick={onDelete}/>
                          </Link>
                        </td>
                        <td><FileDetail token={props.token} data={d}/></td>
                      </tr>
                    )
                  })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default ValidationAuthorized(DaftarIsiFile)

const FileDetail = ({ token, data }) => {
  FileDetail.propTypes = {
    token: PropTypes.string.isRequired,
    data: PropTypes.object
  }

  const [state, setState] = useState({
    modalView: false
  })

  const toggleView = () => {
    setState((prevProps) => ({
      ...prevProps,
      modalView: !state.modalView
    }))
  }

  return (
    <>
      <Link
        onClick={toggleView}
        to={`#${data.id}`}>{data.fileName}</Link>
      <Modal
        size={'lg'}
        isOpen={state.modalView}
        toggle={toggleView}>
        <ModalHeader toggle={toggleView}>File</ModalHeader>
        <ModalBody>
          <ReadFilePdf
            fileName={data.fileName}
            fileType={data.fileType}
            url={`${Axios.defaults.baseURL}/unit-pengolah/isi-berkas-file/read/${data.id}/${token}`}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="warning" onClick={toggleView}>
            <a
              className="text-white"
              href={`${Axios.defaults.baseURL}/unit-pengolah/isi-berkas-file/read/${data.id}/${token}`}
              target='_blank'
              rel='noopener noreferrer'
            ><FontAwesomeIcon icon={faDownload}/> Unduh</a>
          </Button>
          <Button
            color="primary"
            onClick={toggleView}><FontAwesomeIcon icon={faDoorClosed}/> Tutup</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

const FileUploadForm = ({ token, id, onApply }) => {
  FileUploadForm.propTypes = {
    token: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    onApply: PropTypes.func
  }

  const [state, setState] = useState({
    isiBerkasFile: null,
    isFileValid: null
  })

  const handleInputChangeFile = (getFile) => {
    // console.log(getFile);
    if (_.isObject(getFile)) {
      if (getFile.target.files.length > 0) {
        const isiBerkasFile = getFile.target.files[0]
        let isFileValid = null
        if (
          (_.isEqual(isiBerkasFile.type, 'application/pdf')
          ) &&
          (_.inRange(isiBerkasFile.size, 1, 100000000))) {
          isFileValid = true
        } else {
          isFileValid = false
        }
        setState((prevProps) => ({
          ...prevProps,
          isiBerkasFile,
          isFileValid
        }))
      }
    }
  }

  const handleButtonSubmit = () => {
    const formData = new FormData()
    formData.append('daftarIsiBerkasUuid', id)
    formData.append('file', state.isiBerkasFile, state.isiBerkasFile.name)

    Axios({
      method: 'POST',
      url: '/unit-pengolah/isi-berkas-file/upload',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json'
      },
      data: formData
    })
      .then(() => {
        httpResponseNotification.incoming(200, 'File berhasil diunggah')
        onApply()
        setState((prevProps) => ({
          ...prevProps,
          isiBerkasFile: null,
          isFileValid: null
        }))
      })
      .catch(error => {
        // console.error(error);
        if (_.has(error, 'response')) {
          httpResponseNotification.incoming(error.response.status, 'Gagal')
        }
      })
  }

  return (
    <>
      {/* {console.log(state)} */}
      <Card>
        <CardHeader>
          <h3 className="mb-0">Unggah file</h3>
        </CardHeader>
        <CardBody>
          <Form>
            <div className="custom-file">
              <Input
                type="file"
                laceholder="Select file"
                onChange={handleInputChangeFile}
              />
              <FormText>format file: pdf, max size : 100Mb</FormText>
              {_.isBoolean(state.isFileValid) ? <FileInputNotification value={state.isFileValid}/> : null}
            </div>
          </Form>
        </CardBody>
        <CardFooter>
          <Button
            color="primary"
            disabled={!(
              state.isFileValid
            )}
            onClick={handleButtonSubmit}
          ><FontAwesomeIcon icon={faUpload} /> Unggah</Button>
        </CardFooter>
      </Card>
    </>
  )
}

const FileInputNotification = (props) => {
  FileInputNotification.propTypes = {
    value: PropTypes.boolean
  }
  if (_.isBoolean(props.value)) {
    return (
      <>
        {props.value
          ? <FormText color="success">File valid</FormText>
          : <FormText color="danger">File tidak valid</FormText>
        }
      </>
    )
  }
  return (<></>)
}
