// /* eslint-disable */
import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup, FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap'
import Select from 'react-select'
import Toastr from 'toastr'
import { Link, Redirect } from 'react-router-dom'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faFile, faSave, faTrash } from '@fortawesome/free-solid-svg-icons'
import ValidationAuthorized from 'utils/ValidationAuthorized'
import Axios from 'helpers/Axios'
import AuthorizedHeader from 'components/Headers/AuthorizedHeader'

const ArsipUsulPindahForm = (props) => {
  ArsipUsulPindahForm.propTypes = {
    token: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired
  }

  const [state, setState] = useState({
    isSavedData: false,
    isUpdatedData: false,
    isDeletedData: false,

    uid: null,
    shortid: '[Auto]',
    judul: '',
    apakahSudahDiverifikasiUnitKearsipan: null,
    daftarBerkas: [],
    daftarBerkasOptions: [],
    tingkatPerkembanganOptions: [],

    isModalOpen: false
  })

  useEffect(() => {
    getDaftarBerkas(props.token)
    if (!_.isUndefined(props.match.params.uid)) {
      getData(props.token, props.match.params.uid)
    }
  }, [props])

  const getDaftarBerkas = (token) => {
    Axios.get('/unit-pengolah/daftar-berkas-list?format=select_options&sedang-dipinjam=false&retensi-aktif-duetime-expired=true', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          daftarBerkasOptions: res.data
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  const getData = (token, id) => {
    Axios.get(`/unit-pengolah/usul-pindah/${id}?include-daftar-berkas=true`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        const daftarBerkas = []
        response.data.daftarBerkas.map((d, i) => {
          daftarBerkas.push({
            value: d.uuid,
            label: `[${d.nomorFolder}] ${d.judul}`
          })
          return true
        })

        setState((prevProps) => ({
          ...prevProps,
          shortid: response.data.shortid,
          uid: response.data.id,
          judul: response.data.judul,
          apakahSudahDiverifikasiUnitKearsipan: response.data.apakahSudahDiverifikasiUnitKearsipan,
          daftarBerkas: daftarBerkas
        }))
      })
      .catch(error => {
        console.error(error)
      })
  }

  const handleChangeDaftarBerkas = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      daftarBerkas: obj
    }))
  }

  const handleChangeJudul = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      judul: obj.target.value
    }))
  }

  const onSubmit = () => {
    let daftarBerkasString = ''
    state.daftarBerkas.map((d, i) => {
      daftarBerkasString += `${(d.value)}`
      if (i < (state.daftarBerkas.length - 1)) {
        daftarBerkasString += ','
      }
      return daftarBerkasString
    })

    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: _.isNull(state.uid) ? 'POST' : 'PATCH',
      url: _.isNull(state.uid) ? '/unit-pengolah/usul-pindah' : `/unit-pengolah/usul-pindah/${state.uid}`,
      data: {
        judul: state.judul,
        daftarBerkas: `[${daftarBerkasString}]`
      }
    })
      .then((response) => {
        Toastr.success('Data berhasil disimpan!')
        setState((prevProps) => ({
          ...prevProps,
          uid: response.data.detail,
          isSavedData: !!_.isNull(state.uid),
          isUpdatedData: !_.isNull(state.uid)
        }))
      })
      .catch(err => {
        Toastr.error('Failed!')
        if (_.isEqual(err.response.status, 409)) {
          Toastr.warning('Conflict!')
        }
      })
  }

  const toggleModal = () => {
    setState((prevProps) => ({
      ...prevProps,
      isModalOpen: !state.isModalOpen
    }))
  }

  const onDelete = () => {
    Toastr.info('Menghapus data...')
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: 'DELETE',
      url: `/unit-pengolah/usul-pindah/${state.uid}`,
      data: {}
    })
      .then(() => {
        // console.log(res.data);
        Toastr.success('Data berhasil dihapus!')
        setState((prevProps) => ({
          ...prevProps,
          isDeletedData: true
        }))
      })
      .catch(err => {
        console.error('ERROR!', err)
        Toastr.error('Failed!')
      })
  }

  if (state.isSavedData || state.isUpdatedData) {
    return <Redirect to={`/authorized/penyusutan/surat-usulan/usul-pindah/${state.uid}`}/>
  }

  if (state.isDeletedData) {
    return <Redirect to={'/authorized/penyusutan/usul-pindah'}/>
  }

  return (
    <>
      {/* { console.log(state) } */}
      <AuthorizedHeader name="Penyusutan" parentName="Usul Pindah"/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="bg-gradient-white shadow">
              <CardHeader className="bg-white border-0">
                <Link
                  className="btn btn-primary"
                  to="/authorized/penyusutan/usul-pindah">
                  <FontAwesomeIcon icon={faBars}/>
                  <span className="btn-inner--text">Lihat Data</span>
                </Link>
                <Link
                  className="btn btn-secondary"
                  to={`/authorized/penyusutan/surat-usulan/usul-pindah/${props.match.params.uid}`}>
                  <FontAwesomeIcon icon={faFile}/>
                  <span className="btn-inner--text">Surat Usulan</span>
                </Link>
                <Link
                  className="btn btn-secondary"
                  to={`/authorized/penyusutan/berita-acara/usul-pindah/${props.match.params.uid}`}>
                  <FontAwesomeIcon icon={faFile}/>
                  <span className="btn-inner--text">Berita Acara</span>
                </Link>
              </CardHeader>
              <CardBody>
                {_.isNull(state.uid) ? null : <Alert color="warning">Anda akan mengubah data: <strong>{state.judul}</strong></Alert>}
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="9">
                        <FormGroup>
                          <Label>ID</Label>
                          <Input
                            readOnly={true}
                            value={state.shortid}
                            placeholder="ID"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="9">
                        <FormGroup>
                          <Label>Judul</Label>
                          <Input
                            value={state.judul}
                            onChange={handleChangeJudul}
                            placeholder="Judul"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="9">
                        <FormGroup>
                          <Label>Daftar Berkas</Label>
                          <Select
                            isMulti={true}
                            value={state.daftarBerkas}
                            onChange={handleChangeDaftarBerkas}
                            options={state.daftarBerkasOptions}
                          />
                          <FormText>Daftar Berkas yang telah melewati masa aktif (JRA) dan tidak sedang dipinjamkan</FormText>
                        </FormGroup>
                        <Card>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  disabled={(
                    !state.daftarBerkas.length > 0 ||
                    _.isEqual(state.judul, '') ||
                      state.apakahSudahDiverifikasiUnitKearsipan
                  )}
                  color='primary'
                  onClick={onSubmit}
                ><FontAwesomeIcon icon={faSave}/> Simpan</Button>
                {_.isNull(state.uid)
                  ? null
                  : <Button
                    disabled={state.apakahSudahDiverifikasiUnitKearsipan}
                    color='danger'
                    onClick={toggleModal}
                  ><FontAwesomeIcon icon={faTrash}/> Hapus</Button>}
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <Modal
        isOpen={state.isModalOpen}
        backdrop='static' centered={true}
        toggle={toggleModal}
      >
        <ModalHeader
          toggle={toggleModal}
        >Konfirmasi Hapus Data</ModalHeader>
        <ModalBody>
          PERHATIAN! Anda akan menghapus data ini, data yang terhapus tidak bisa dikembalikan. Apakah Anda yakin ingin
          menghapus data ini?
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={onDelete}
          >YA, HAPUS</Button>{' '}
          <Button
            color="secondary"
            onClick={toggleModal}
          >Batal</Button>
        </ModalFooter>
      </Modal>
    </>
  )
}

export default ValidationAuthorized(ArsipUsulPindahForm)
