// /* eslint-disable */
import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  Table
} from 'reactstrap'
import Toastr from 'toastr'
import { Redirect } from 'react-router-dom'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import ValidationAuthorized from 'utils/ValidationAuthorized'
import Axios from 'helpers/Axios'
import AuthorizedHeader from 'components/Headers/AuthorizedHeader'

const UsulPindahFormStep2 = (props) => {
  UsulPindahFormStep2.propTypes = {
    token: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired
  }

  const [state, setState] = useState({
    isSavedData: false,
    uid: null,
    shortid: '',
    judul: '',
    daftarBerkasOptions: [],
    daftarBerkasCheckedState: []
  })

  useEffect(() => {
    getDaftarBerkas()
    if (!_.isUndefined(props.match.params.uid)) {
      getData(props.match.params.uid)
    }
  }, [props])

  const getDaftarBerkas = () => {
    Axios.get('/unit-pengolah/daftar-berkas-list?format=select_options&sedang-dipinjam=false&retensi-aktif-duetime-expired=true&sudah-proses-pindah=null', {
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    })
      .then(res => {
        setState((prevProps) => ({
          ...prevProps,
          daftarBerkasOptions: res.data,
          daftarBerkasCheckedState: new Array(res.data.length).fill(false)
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  const getData = (id) => {
    Axios.get(`/unit-pengolah/usul-pindah/${id}`, {
      headers: {
        Authorization: `Bearer ${props.token}`
      }
    })
      .then(response => {
        setState((prevProps) => ({
          ...prevProps,
          uid: response.data.id,
          shortid: response.data.shortid,
          judul: response.data.judul
        }))
      })
      .catch(error => {
        console.error(error)
      })
  }

  const handleChangeJudul = (obj) => {
    setState((prevProps) => ({
      ...prevProps,
      judul: obj.target.value
    }))
  }

  const handleOnChange = (position) => {
    const tempState = []
    state.daftarBerkasCheckedState.map((d, i) => {
      tempState.push(i === position ? !d : d)
      return tempState
    })
    setState((prevProps) => ({
      ...prevProps,
      daftarBerkasCheckedState: tempState
    }))
  }

  const onSubmit = () => {
    let daftarBerkasString = ''
    state.daftarBerkasOptions.map((d, i) => {
      if (state.daftarBerkasCheckedState[i]) {
        daftarBerkasString += `${(d.value)},`
      }
      return daftarBerkasString
    })
    Axios({
      headers: {
        Authorization: `Bearer ${props.token}`
      },
      method: 'PATCH',
      url: `/unit-pengolah/usul-pindah/${state.uid}`,
      data: {
        judul: state.judul,
        daftarBerkas: `[${daftarBerkasString}]`
      }
    })
      .then((response) => {
        Toastr.success('Menuju halaman selanjutnya...')
        setState((prevProps) => ({
          ...prevProps,
          uid: response.data.detail,
          isSavedData: true
        }))
      })
      .catch(err => {
        Toastr.error('Failed!')
        if (_.isEqual(err.response.status, 409)) {
          Toastr.warning('Conflict!')
        }
      })
  }

  if (state.isSavedData) {
    return <Redirect to={`/authorized/penyusutan/add/step-3/usul-pindah/${state.uid}`}/>
  }

  return (
    <>
      {/* {console.log(state)} */}
      <AuthorizedHeader name="Penyusutan" parentName="Usul Pindah > Langkah 2"/>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h1 className="text-black-50 text-underline text-capitalize">langkah #2, memilih daftar berkas</h1>
              </CardHeader>
              <CardBody>
                <Form>
                  <FormGroup className="row pl-lg-4">
                    <Label md="1">
                      ID
                    </Label>
                    <Col md="8">
                      <Input
                        readOnly={true}
                        value={state.shortid}
                        placeholder="ID"
                        type="text"
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row pl-lg-4">
                    <Label md="1">
                      Judul
                    </Label>
                    <Col md="8">
                      <Input
                        value={state.judul}
                        onChange={handleChangeJudul}
                        placeholder="Judul"
                        type="text"
                      />
                    </Col>
                  </FormGroup>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="9">
                        <h2>Daftar Berkas</h2>
                        <FormText>* Daftar Berkas yang telah melewati masa aktif (JRA) dan tidak sedang
                          dipinjamkan</FormText>
                        <Table bordered responsive>
                          <thead className="thead-light">
                          <tr>
                            <th>_</th>
                            <th>Nama Berkas</th>
                          </tr>
                          </thead>
                          <tbody className="list">
                          {state.daftarBerkasOptions.map((d, i) => {
                            return (
                              <tr key={i}>
                                <td className="text-center">
                                  <Input
                                    type="checkbox"
                                    checked={state.daftarBerkasCheckedState[i]}
                                    onChange={() => handleOnChange(i)}
                                  />
                                </td>
                                <td>{d.label}</td>
                              </tr>
                            )
                          })}
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  disabled={!state.daftarBerkasCheckedState.includes(true)}
                  color='primary'
                  onClick={onSubmit}
                ><FontAwesomeIcon icon={faArrowRight}/> Selanjutnya</Button>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  )
}

export default ValidationAuthorized(UsulPindahFormStep2)
