import React from 'react'
import { PropTypes } from 'prop-types'
import _ from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faMinusCircle } from '@fortawesome/free-solid-svg-icons'

const IconBoolean = (props) => {
  let result = null
  if (_.isBoolean(props.value)) {
    result = <FontAwesomeIcon icon={faMinusCircle}/>
    if (props.value) {
      result = <FontAwesomeIcon icon={faCheck}/>
    }
  }
  return (
    <>{result}</>
  )
}

IconBoolean.propTypes = {
  value: PropTypes.bool.isRequired
}

export default IconBoolean
