// /* eslint-disable */
import React from 'react'
import { PropTypes } from 'prop-types'
import { Table } from 'reactstrap'
import DatetimeFormatV2 from './DatetimeFormatV2'

const ArsipSudahPindahDetail = (props) => {
  ArsipSudahPindahDetail.propTypes = {
    token: PropTypes.string.isRequired,
    data: PropTypes.object.isRequired
  }

  return (
    <>
      <h2>Detail</h2>
      <Table bordered>
        <tbody>
        <tr>
          <td className="text-right col-md-1">Nomor Folder :</td>
          <td><code className="text-lg">{props.data.nomorFolder}</code></td>
        </tr>
        <tr>
          <td className="text-right">Judul :</td>
          <td><h5>{props.data.judul}</h5></td>
        </tr>
        <tr>
            <td className="text-right">Kode Klasifikasi :</td>
            <td>{props.data.jraKlasifikasi.code} ({props.data.jraKlasifikasi.name})</td>
        </tr>
        </tbody>
      </Table>
      <h2>Penyusutan</h2>
      <Table bordered>
        <tbody>
        <tr>
          <td className="text-right col-md-1">Tanggal Proses Pindah :</td>
          <td><DatetimeFormatV2 value={props.data.tanggalProsesPindah} /></td>
        </tr>
        </tbody>
      </Table>
    </>
  )
}

export default ArsipSudahPindahDetail
